@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
* {
  scroll-behavior: smooth;
  font-family: sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* global styling */
.leaflet-control-geosearch *,
.leaflet-control-geosearch *:before,
.leaflet-control-geosearch *:after {
  box-sizing: border-box;
}
.leaflet-control-geosearch button{
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  line-height: 20px;
  
}
.leaflet-control-geosearch button:hover{
  background-color: rgba(205, 205, 205, 0.914);
  border-radius: 0 4px 4px 0;
}
/* leaflet button styling */
.leaflet-control-geosearch .leaflet-bar-part {
  border-radius: 4px;
  border-bottom: none;
}

.leaflet-control-geosearch a.leaflet-bar-part:before,
.leaflet-control-geosearch a.leaflet-bar-part:after {
  position: absolute;
  display: block;
  content: '';
}

/* magnifying glass */
.leaflet-control-geosearch a.leaflet-bar-part:before {
  top: 15px;
  left: 13px;
  width: 6px;
  border-top: 2px solid #555;
  transform: rotateZ(45deg);
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
  top: 8px;
  left: 8px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 2px solid #555;
}

/* resets for pending and error icons */
.leaflet-control-geosearch.error a.leaflet-bar-part:before,
.leaflet-control-geosearch.pending a.leaflet-bar-part:before {
  display: none;
}

.leaflet-control-geosearch.pending a.leaflet-bar-part:after,
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  border-radius: 50%;
}

/* pending icon */
.leaflet-control-geosearch.pending a.leaflet-bar-part:after {
  content: '';
  border: 2px solid #555;
  border-top: 2px solid #f3f3f3;
  animation: spin 1s linear infinite;
}

/* error icon */
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  content: '!';
  line-height: initial;
  font-weight: 600;
  font-size: 18px;
  border: none;
}

/* search form styling */
.leaflet-control-geosearch form {
  display: none;
  position: absolute;
  top: 0;
  left: 36px;
  border-radius: 0 4px 4px 0;
  background-color: #fff;
  background-clip: padding-box;
  z-index: -1;
  height: auto;
  margin: 0;
  padding: 0 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.399);
  padding-right: 40px;
}

.leaflet-geosearch-button form.open {
  border-radius: 0 4px 4px 4px;
}
.leaflet-control-geosearch.active form {
  display: block;
}

.leaflet-geosearch-button.active .leaflet-bar-part {
  border-radius: 4px 0 0 4px;
  width: 36px;
}

.leaflet-geosearch-button form {
  max-width: 350px;
}

.leaflet-control-geosearch form input {
  min-width: 200px;
  width: 100%;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  height: 26px;
  border: none;
  border-radius: 0 4px 4px 0;
  text-indent: 8px;
  box-shadow: none;
}

.leaflet-touch .leaflet-geosearch-bar form {


}

.leaflet-touch .leaflet-geosearch-bar form input {
  height: 30px;
}

.leaflet-control-geosearch .results {
  background: #fff;
}

.leaflet-control-geosearch .results > * {
  line-height: 24px;
  padding: 0 8px;
  border: 1px solid transparent;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaflet-control-geosearch .results.active {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}

.leaflet-control-geosearch .results > .active,
.leaflet-control-geosearch .results > :hover {
  background-color: #f8f8f8;
  border-color: #c6c6c6;
  cursor: pointer;
}

/* add missing border to form */
.leaflet-control-geosearch .results.active:after {
  content: '';
  display: block;
  width: 0;
  position: absolute;
  left: -2px;
  bottom: -2px;
  top: 30px;
}

.leaflet-touch .leaflet-control-geosearch .results.active:after {
  border-left: 2px solid rgba(0, 0, 0, .2);
}

/* animations */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.leaflet-top .leaflet-geosearch-bar,
.leaflet-bottom .leaflet-geosearch-bar {
  display: none;
}

.leaflet-geosearch-bar {
  position: relative;
  display: block;
  height: auto;
  width: 400px;
  max-width: calc(100% - 120px);
  margin: 10px auto 0;
  cursor: auto;
  z-index: 1000;
}

.leaflet-geosearch-bar form {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  border-radius: 4px;
}

.leaflet-geosearch-bar form input {
  min-width: 100%;
  width: 100%;
}

.leaflet-geosearch-bar .results.active:after {
  opacity: .2;
}

.leaflet-right .leaflet-control-geosearch form {
  right: 28px;
  left: initial;
  border-radius: 4px 0 0 4px;
  border-left: inherit;
  border-right: none;
}

.leaflet-control-geosearch a.reset {
  color: black;
  font-weight: bold;
  position: absolute;
  line-height: 26px;
  padding: 0 8px;
  right: 0;
  top: 0;
  cursor: pointer;
  border: none;
  text-decoration: none;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
}

.leaflet-touch .leaflet-control-geosearch a.reset {
  line-height: 30px;
}

.leaflet-control-geosearch a.reset:hover {
  background: #f5f5f5;
}


.scrollIcon {
  width: 20px;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  border-radius: 12px;
  position: relative;
}
.scrollIconDot {
  position: absolute;
  top: 8px;
  left: calc(50% - 1.5px);
  display: inline-block;
  background-color: white;
  border-radius: 100%;
  width: 3px;
  height: 3px;
  -webkit-animation: animation-19arblj 2s ease-in-out both infinite;
  animation: animation-19arblj 2s ease-in-out both infinite;
}

.scrollIconDot:last-child {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}


@keyframes animation-19arblj {
  0% {
      -webkit-transform: translateY(-170%);
      -moz-transform: translateY(-170%);
      -ms-transform: translateY(-170%);
      transform: translateY(-170%);
      opacity: 0;
  }

  35% {
      opacity: 0.5;
  }

  50%,55% {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
  }

  85% {
      opacity: 0.5;
  }

  100% {
      -webkit-transform: translateY(150%);
      -moz-transform: translateY(150%);
      -ms-transform: translateY(150%);
      transform: translateY(150%);
      opacity: 0;
  }
}


.custom-header{
  @apply bg-base-100 border-gray-500;
}
.custom-header{
  @apply shadow-none;
  background-color: #ffffff10;
}

.landing-section{
  height: 100vh;
}
.custom-intro{
 
  background-color:#1b4e89 ;
  background-image: url('./components/Images/bg06.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  background-origin: content-box, padding-box;
background-position: center;
}


/* OpenSauceOne Fonts */
@font-face {
  font-family: 'OpenSauceOne';
  src: url('../public/font/OpenSauceOne-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../public/font/OpenSauceOne-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../public/font/OpenSauceOne-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../public/font/OpenSauceOne-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../public/font/OpenSauceOne-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../public/font/OpenSauceOne-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

/* Repeat for other weights like Light, Medium, SemiBold, ExtraBold, etc. */

/* OpenSauceSans Fonts */
@font-face {
  font-family: 'OpenSauceSans';
  src: url('../public/font/OpenSauceSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceSans';
  src: url('../public/font/OpenSauceSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* Add more styles for Bold, Light, ExtraBold, etc. */

/* OpenSauceTwo Fonts */
@font-face {
  font-family: 'OpenSauceTwo';
  src: url('../public/font/OpenSauceTwo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Continue with the other variants like Italic, Bold, ExtraBold, etc. */


* {
  font-family: 'OpenSauceOne', sans-serif;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff00 inset !important; /* Changes the background color */
  box-shadow: 0 0 0px 1000px #ffffff00 inset !important;
  -webkit-text-fill-color: #000 !important; /* Changes the text color */
}