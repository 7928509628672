@tailwind base;
@tailwind components;
@tailwind utilities;


.loading-indicator:before {
    content: '';
    background: #00000080;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: ' ';
    position: fixed;
    top: 40%;
    left: 45%;
    z-index: 10010;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.2rem;        
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0474bf; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.custom-btn {
  @apply input rounded-3xl input-bordered w-full;
  height: 44px;
}
.custom-input {
  @apply input rounded-3xl input-bordered w-full text-base-content;
  height: 44px;

}
.custom-input-small {
  @apply input rounded-none input-sm  ;


}
.custom-input-close {
padding-right: 38px !important;

}


.custom-btn {
  @apply btn rounded-3xl w-full bg-brand-500 text-white hover:bg-brand-600 border-shark-950 disabled:bg-brand-disabled-600 disabled:text-brand-disabled-500 ;
  height: 44px;

}
.custom-second-btn {
  @apply btn rounded-3xl w-full bg-brand-500 text-white hover:bg-brand-600 border-shark-950 disabled:bg-brand-disabled-600 disabled:text-brand-disabled-500 ;
  height: 44px;

}
.custom-link {
  @apply text-brand-400 hover:text-brand-300 hover:underline;
  height: 44px;
text-shadow: 0 0 1px #000000;

}

.custom-input-pass{
  padding-right:40px !important ;
}
@layer components {
 *[data-theme="dark"] .btn-brand {
    @apply bg-gray-800 text-base border-gray-900 py-2 px-4 rounded-md font-semibold;
  }
  *[data-theme="dark"] .btn-brand:hover {
    @apply bg-gray-900;
  }
  *[data-theme="dark"]  .btn-brand:active {
    @apply bg-brand-700;
  }
  *[data-theme="dark"]  .btn-brand:disabled {
    @apply bg-brand-disabled-500 border-brand-disabled-500 text-shark-600 cursor-not-allowed;
  }
  /* light */
  *[data-theme="light"] .btn-brand {
    @apply bg-shark-800 text-greenWhite-50 border-shark-800 py-2 px-4 rounded-md font-semibold;
  }
  *[data-theme="light"] .btn-brand:hover {
    @apply bg-shark-950  border-shark-950;
  }
  *[data-theme="light"]  .btn-brand:active {
    @apply bg-shark-950 border-shark-950;
  }
  *[data-theme="light"]  .btn-brand:disabled {
    @apply bg-shark-600 border-shark-600 text-shark-300 cursor-not-allowed;
  }
}

.custom-bg{

  background-color: linear-gradient(90deg, rgb(245 203 83) 0%, rgb(245 203 83) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
}
.custom_trip_cart{

  
}

.custom-joyride .react-joyride__tooltip-footer > *:first-child {
  display: none;
}

.custom-form-loading{
  filter: blur(3px);
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.custom-card-row{
  width: calc(50% - 25px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.city-filter {
  position: relative;
}

.city-search-input {
  @apply text-base-content border-base-300;
  width: 100%;
  padding: 8px;
  border: 1px solid;
  border-radius: 4px;
}

.city-dropdown {
  @apply bg-base-100 border-base-300 text-secondary;
  position: absolute;
  width: 100%;
  border: 1px solid ;
  border-radius: 4px;
  margin-top: 4px;
  z-index: 1000;
  
}
.city-dropdown.close{
  display:none ;
}

.city-dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.city-dropdown-item:hover(:not(.loading)) {
  @apply bg-brand-100;
}

.selected-city {
  margin-top: 16px;
}

.city-dropdown-input{

  padding:10px;
 margin-bottom: 20px;
}

.city-dropdown-backdrop{
  position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
}


.truncate-text {
  white-space: nowrap;      
  overflow: hidden;         
  text-overflow: ellipsis;  
  display: block;           
}
.text-shadow-custom {
  text-shadow: 0px 2px 2px #989898
}

.custom-menu li{
 margin-bottom: 20px;
 font-size: 20px;
 cursor: pointer;
 margin-left: 30px;
}
.custom-menu li:hover,.custom-menu li a:hover{
  @apply text-brand-600;
background-color: transparent !important;

 }
.custom-menu li a{
  font-size: 20px;
}

.filter-btn{
  @apply flex lg:flex-col lg:rounded-r-3xl lg:rounded-l-none
   lg:w-auto lg:border-t-0 lg:border-l-[1px] 
   border-t-[1px]  
  rounded-b-3xl  rounded-t-none w-full
  flex-row bg-brand-200 text-brand-600
  justify-center items-center cursor-pointer;
    padding: 17px;
    height: 100%;
    height: auto;
    border-color: #00000025;
    min-width: 100px;
    
}

.filter-btn-right.filter-btn-right{
 @apply w-[50%] rounded-r-none 
 lg:w-[70px] lg:min-w-[70px] lg:px-[5px]
 bg-brand-300 text-brand-500
}
.filter-btn-right.filter-btn-right:hover{
  @apply 
  bg-brand-400 text-brand-800
 }

.filter-btn-left.filter-btn-left{
  @apply w-[50%] rounded-l-none lg:w-[100px]
  
}

.filter-btn:hover{
  @apply bg-brand-300;

}

html[data-theme="dark"] .filter-btn:hover{
  @apply bg-brand-400 text-brand-900;

}

.filter-btn:disabled{
  @apply bg-[#70aae1c7] text-[#334e67b0] ;

}
.filter-row{
  @apply md:w-[30%] w-full ;

}
html[data-theme="dark"] .filter-btn:disabled{
  @apply bg-[#4d8bc9ac] text-[#3333336d] ;
}

.cart-landing{
  @apply  bg-white rounded-xl overflow-hidden p-2 gap-3 mb-10 ;
}

.custom-text-shadow-sm {
  text-shadow: 0 0 2px #000;
}
.custom-tab{
  @apply text-base-content;
  border-bottom: 3px solid #00000000;
  padding: 0 10px;
  cursor: pointer;


}
.custom-tab:hover{
  @apply text-brand-500;
}
.active-tab{
@apply border-brand-600;
}

.filter-col{
  @apply grow-[1] w-full md:w-auto md:max-w-[230px];
padding: 2px 0 10px;

}
 .input-white-transparent{
  @apply border-white bg-[transparent] hover:border-[#ffffff31]
   hover:outline-2 hover:outline-white text-white outline-none
 }
 .input-transparent{
  @apply  bg-[#ffffff74] hover:border-[#46464672]
  outline-2 border-gray-300 text-gray-600

 }

 .input-white label span {
  @apply text-white
 }

 .input-gray label span {
  @apply text-gray-600
 }
 
 .custom-title{
  @apply font-semibold text-center text-brand-100;
  text-shadow: 0 0 10px #000000;
 }

 .custom-container{
  @apply max-w-[1200px] py-0 px-[10px] sm:px-[40px] mx-auto my-0;

 }

.custom-modal.custom-modal{
  @apply bg-brand-900
}

.custom-secondary-modal.custom-secondary-modal{
  @apply bg-brand-200
}


/* In your main.css or styles.css */
.btn-brand-custom.btn-brand-custom  {
  @apply btn bg-brand-500
  rounded-3xl border-brand-600
  text-white px-4 py-2  font-semibold;
}

.btn-brand-custom.btn-brand-custom:hover {
  @apply bg-brand-600;
}

.btn-brand-custom.btn-brand-custom:active {
  @apply bg-brand-700;
}

.btn-brand-custom.btn-brand-custom:disabled {
  @apply bg-brand-disabled-500 text-brand-disabled-600 cursor-not-allowed;
}

.test{
  position: absolute;
  background: #0b1725;
  border-radius: 0 0 20px 20px;
  width: 50px;
  text-align: center;
  top: 0;
  padding-top: 9px;
  box-shadow: 0px 2px 2px black;
}

 .btn[disabled].btn-primary, .btn:disabled.btn-primary {
  --tw-border-opacity: 0;
  background-color: #569adbd9;
  --tw-bg-opacity: 1;
  color: #0000008a;
  --tw-text-opacity: 0.9;
}